import React, { ReactElement } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { InventoryControlType } from '@types'

type Props = {
  value: InventoryControlType
  onChange: (value: InventoryControlType) => void
  disabled?: boolean
  className?: string
  formControlProps?: Record<any, any>
}

const InventoryControlSelect = ({
  value,
  onChange,
  disabled = false,
  formControlProps,
  ...selectProps
}: Props): ReactElement => {
  const handleOnChange = ({ target: { value } }: SelectChangeEvent<number>) =>
    onChange(value as InventoryControlType)

  return (
    <FormControl {...formControlProps}>
      <InputLabel>Inventory Control</InputLabel>
      <Select
        disabled={disabled}
        label='Inventory Control'
        onChange={handleOnChange}
        value={value as any}
        {...selectProps}
      >
        <MenuItem value='unlimited'>unlimited</MenuItem>
        <MenuItem value='limited'>limited</MenuItem>
        <MenuItem value='daily'>daily</MenuItem>
      </Select>
    </FormControl>
  )
}

export default InventoryControlSelect
