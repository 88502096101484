import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import CreateProductStoreDialog from '@shared/dialogs/create-product-store-dialog'
import MaterialTable, { MTableToolbar } from '@material-table/core'
import { Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import {
  createProductStore,
  removeProductAtStore,
  setPriceForVariantAtStore,
} from '@services/product-service'
import { fetchProductsStoresForStoreId } from '@services/store-service'
import { fetchVendors } from '@services/vendor-service'

import AddDefaultProducts from './add-default-products'
import EmptyMenu from './empty-menu'
import { getAllVendorProducts } from '@store/actions/vendor-products'
import { getProductsForStore } from '@store/selectors/products-stores'
import { getVendorRegions } from '@store/actions/vendor-regions'
import { selectVendorProducts } from '@store/selectors/vendor-products'
import { setFlashMessage } from '@store/reducers/flash-message/actions'

const useStyles = makeStyles()(() => ({
  row: {
    display: 'flex',
    paddingRight: 8,
    alignItems: 'center',
  },
}))

const mapStateToProps = (state, ownProps) => {
  return {
    products: getProductsForStore(state, ownProps.storeId),
    vendors: state.Vendors,
    vendorProducts: selectVendorProducts(state),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    createProductStore: createProductStore(dispatch),
    fetchProductsStores: () =>
      fetchProductsStoresForStoreId(dispatch)(ownProps.storeId),
    fetchVendors: fetchVendors(dispatch),
    removeProductAtStore: (productId) =>
      removeProductAtStore(dispatch)(ownProps.storeId, productId),
    setStoreVariantPrice: (variantId, price) =>
      setPriceForVariantAtStore(dispatch)(ownProps.storeId, variantId, price),
    setFlashMessage: (message, variant) =>
      dispatch(setFlashMessage(message, variant)),
    getVendorProducts: () => dispatch(getAllVendorProducts()),
    getVendorRegions: () => dispatch(getVendorRegions()),
  },
})

function MenuWidget(props) {
  const { actions, products } = props
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [createDialogLoading, setCreateDialogLoading] = useState(false)
  const [hasFetchedData, setHasFetchedData] = useState(false)
  const { classes: styles } = useStyles()
  useEffect(() => {
    if (!hasFetchedData) {
      setHasFetchedData(true)

      Promise.all([actions.fetchProductsStores()]).catch((error) =>
        actions.setFlashMessage(error.message, 'error'),
      )
    }
  }, [hasFetchedData])

  const tableData = useMemo(() => {
    const parents = products.map((p) => ({
      id: p.product.id,
      productId: p.product.id,
      name: p.product.name,
      product_type: p.product.product_type,
      sku: null,
      price: null,
    }))
    return parents
      .concat(
        products.map((p) => {
          return p.product.variants.map((variant) => ({
            id: `v${variant.id}`,
            name: null,
            variantId: variant.id,
            productId: p.product.id,
            sku: variant.sku,
            parentId: p.product.id,
            price: p.productStore.prices.find((x) => x.variant_id == variant.id)
              .price,
          }))
        }),
      )
      .flat()
  }, [products])

  return (
    <div className={props.className}>
      <CreateProductStoreDialog
        hideProduct={false}
        hideStore
        initialData={{
          storeId: props.storeId,
        }}
        loading={createDialogLoading ? true : undefined}
        onClose={() => setCreateDialogOpen(false)}
        onConfirm={(data) => {
          setCreateDialogLoading(true)
          actions
            .createProductStore(data)
            .then(() => {
              setCreateDialogLoading(false)
              setCreateDialogOpen(false)
              actions.setFlashMessage('Created Product at Store', 'success')
            })
            .catch((err) => {
              setCreateDialogLoading(false)
              actions.setFlashMessage(err.message, 'error')
            })
        }}
        open={createDialogOpen}
      />
      {products.length === 0 ? (
        <Paper>
          {props.editable && (
            <EmptyMenu
              onAddProduct={() => setCreateDialogOpen(true)}
              storeId={props.storeId}
            />
          )}
        </Paper>
      ) : (
        <MaterialTable
          actions={
            props.editable
              ? [
                  {
                    icon: 'add',
                    tooltip: 'Add Product',
                    isFreeAction: true,
                    onClick: () => setCreateDialogOpen(true),
                  },
                ]
              : undefined
          }
          columns={[
            {
              title: 'Product',
              field: 'name',
              editable: 'never',
            },
            {
              title: 'Type',
              field: 'product_type',
              editable: 'never',
            },
            {
              title: 'SKU',
              field: 'sku',
              editable: 'never',
            },
            {
              title: 'Price (cents)',
              field: 'price',
            },
          ]}
          components={{
            Toolbar: function renderToolbar(toolbarProps) {
              return (
                <div className={styles.row}>
                  <MTableToolbar {...toolbarProps} />
                  {props.editable && (
                    <AddDefaultProducts storeId={props.storeId} />
                  )}
                </div>
              )
            },
          }}
          data={tableData}
          editable={
            props.editable
              ? {
                  isEditHidden: (x) => !x.parentId,
                  isDeleteHidden: (x) => x.parentId,
                  onRowUpdate: async ({ variantId, price }) => {
                    const newPrice = parseInt(price, 10)
                    if (isNaN(newPrice)) {
                      return
                    } else {
                      return actions
                        .setStoreVariantPrice(variantId, newPrice)
                        .then(() =>
                          actions.setFlashMessage(
                            'Menu item updated',
                            'success',
                          ),
                        )
                        .catch((e) =>
                          actions.setFlashMessage(e.message, 'error'),
                        )
                    }
                  },
                  onRowDelete: async ({ productId }) => {
                    return actions
                      .removeProductAtStore(productId)
                      .then(() =>
                        actions.setFlashMessage('Product removed', 'success'),
                      )
                      .catch((e) => actions.setFlashMessage(e.message, 'error'))
                  },
                }
              : undefined
          }
          options={{
            actionsColumnIndex: 5,
          }}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.parentId)
          }
          title='Menu'
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuWidget)
