import AttributeForm from './attribute-form'
import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useState } from 'react'
import { AppDispatch, ProductTypeType } from '@types'
import { Button, Grid } from '@mui/material'
import { createAttribute } from '@store/actions/attributes'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const CreateAttribute = (): ReactElement => {
  const [name, setName] = useState('')
  const [label, setLabel] = useState('')
  const [productType, setProductType] = useState<ProductTypeType>('beverage')
  const [isAdjective, setIsAdjective] = useState(false)
  const [includesGoods, setIncludesGoods] = useState(false)
  const [modifiedBy, setModifiedBy] = useState<null | number>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleCreate = async () => {
    const newAttribute = await dispatch(
      createAttribute({
        name,
        label,
        product_type: productType,
        is_adjective: isAdjective,
        includes_goods: includesGoods,
        modifier_attribute_id: modifiedBy,
      }),
    )
    navigate(`/attributes/${newAttribute.id}/edit`)
  }

  return (
    <ErrorBoundary>
      <AttributeForm
        includesGoods={includesGoods}
        isAdjective={isAdjective}
        label={label}
        modifiedBy={modifiedBy}
        name={name}
        onIncludesGoodsChange={setIncludesGoods}
        onIsAdjectiveChange={setIsAdjective}
        onLabelChange={setLabel}
        onModifiedByChange={setModifiedBy}
        onNameChange={setName}
        onProductTypeChange={setProductType}
        productType={productType}
      />
      <Grid container sx={{ pt: 5 }}>
        <Button color='secondary' onClick={handleCreate} variant='contained'>
          Create
        </Button>
      </Grid>
    </ErrorBoundary>
  )
}

export default CreateAttribute
