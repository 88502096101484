import React, { ReactElement, useState } from 'react'
import Table from '@components/shared/deprecated/table'
import { AppDispatch, OrderProductWithOrderIdType, OrderType } from '@types'
import { Button, CircularProgress, TableCell, TableRow } from '@mui/material'
import { EmailOutlined, Undo } from '@mui/icons-material'

import RefundModal from './refund-modal'
import { Link } from 'react-router-dom'
import { formatCurrency } from '@utils/number-utils'
import { formatShort } from '@utils/date-utils'
import { hasPerms, hasPermsAtStore } from '@utils/has-permission'
import { resendReceipt } from '@store/reducers/orders/actions'
import { useDispatch } from 'react-redux'

type Props = {
  order: OrderType
}

const OrderDetails = ({ order }: Props): ReactElement => {
  const [refundOrderProductIds, setRefundOrderProductIds] = useState<number[]>(
    [],
  )
  const [allSubmitting, setAllSubmitting] = useState<boolean>(false)
  const [idSubmitting, setIdSubmitting] = useState<null | number>(null)
  const [receiptSubmitting, setReceiptSubmitting] = useState(false)
  const isSubmitting = idSubmitting !== null || allSubmitting
  const userCanRefund =
    hasPerms('orders:refund') ||
    hasPermsAtStore('stores:refund', order.store_id)
  const dispatch = useDispatch<AppDispatch>()

  const orderProducts = Object.keys(order.products).map(
    (id): OrderProductWithOrderIdType => {
      return { ...order.products[id], id }
    },
  )

  const isFullyRefunded = !orderProducts.some(
    (product) => product.refunded_at === null,
  )

  const nonRefundedOrderProductIds = orderProducts.reduce(
    (ids: number[], op) => {
      if (!op.refunded_at) ids.push(parseInt(op.id, 10))
      return ids
    },
    [],
  )

  const handleRefundModalClose = () => {
    setIdSubmitting(null)
    setAllSubmitting(false)
  }

  const handleResendReceipt = async () => {
    setReceiptSubmitting(true)
    await dispatch(resendReceipt(order.id))
    setReceiptSubmitting(false)
  }

  const toolbarButtons = [
    {
      disabled:
        receiptSubmitting || !['paid', 'assigned'].includes(order.status),
      disableTooltip: true,
      title: 'Resend Receipt',
      variant: 'outlined',
      onClick: handleResendReceipt,
      startIcon: <EmailOutlined />,
    },
  ]
  if (userCanRefund) {
    toolbarButtons.push({
      disabled: isSubmitting || isFullyRefunded,
      disableTooltip: true,
      title: 'Refund Entire Order',
      variant: 'outlined',
      startIcon: allSubmitting ? <CircularProgress size={20} /> : <Undo />,
      onClick: async () => {
        setAllSubmitting(true)
        setRefundOrderProductIds(nonRefundedOrderProductIds)
      },
    })
  }

  const renderRefundedColumn = (
    val: OrderProductWithOrderIdType['refunded_at'],
    row: OrderProductWithOrderIdType,
  ) => {
    const paymentPending = order.transactions.some(
      (t) => t.state == 'authorized',
    )
    const isRefunded = val !== null
    const rowId = parseInt(row.id, 10)

    const onClick = async () => {
      setIdSubmitting(rowId)
      setRefundOrderProductIds([rowId])
    }

    const getRawValue = () => (isRefunded ? formatShort(val) : 'false')

    return isRefunded || !userCanRefund || paymentPending ? (
      getRawValue()
    ) : (
      <Button
        disabled={isSubmitting}
        onClick={onClick}
        startIcon={
          idSubmitting === rowId ? <CircularProgress size={20} /> : <Undo />
        }
        variant='outlined'
      >
        Refund
      </Button>
    )
  }

  return (
    <>
      <RefundModal
        onClose={handleRefundModalClose}
        orderId={order.id}
        orderProductIds={refundOrderProductIds}
      />
      <Table
        data={orderProducts}
        fields={[
          'name',
          'variant',
          'special_instructions',
          {
            field: 'subtotal',
            transform: (subtotal: OrderProductWithOrderIdType['subtotal']) =>
              formatCurrency(subtotal),
          },
          {
            field: 'price',
            transform: (price: OrderProductWithOrderIdType['price']) =>
              formatCurrency(price),
          },
          {
            field: 'refunded_at',
            transform: renderRefundedColumn,
          },
        ]}
        headers={[
          'Product Name',
          'Variant',
          'Special Instructions',
          'Subtotal',
          'Final Price',
          'Refund?',
        ]}
        rowKey='id'
        title={'Order Details'}
        toolbarButtons={toolbarButtons}
      >
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Subtotal</TableCell>
          <TableCell>{formatCurrency(order.subtotal)}</TableCell>
          <TableCell colSpan={1} />
        </TableRow>
        {order.promotions.map((promotion, i) => (
          <TableRow key={`promo-${i}`}>
            <TableCell colSpan={2} />
            <TableCell>
              <Link to={`/promotions/${promotion.id}`}>
                &quot;{promotion.name}&quot;
              </Link>
            </TableCell>
            <TableCell>{formatCurrency(promotion.amount)}</TableCell>
            <TableCell colSpan={1} />
          </TableRow>
        ))}
        {order.credits.map((credit, i) => (
          <TableRow key={`credit-${i}`}>
            <TableCell colSpan={2} />
            <TableCell>
              <Link to={`/users/${order.user_id}`}>Customer Credit</Link>
            </TableCell>
            <TableCell>{formatCurrency(credit.claimed)}</TableCell>
            <TableCell colSpan={1} />
          </TableRow>
        ))}
        {Object.keys(order.tax).map((taxKey) => (
          <TableRow key={taxKey}>
            <TableCell colSpan={2} />
            <TableCell>{taxKey}</TableCell>
            <TableCell>{formatCurrency(order.tax[taxKey])}</TableCell>
            <TableCell colSpan={1} />
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Tip</TableCell>
          <TableCell>{formatCurrency(order.tip_amount)}</TableCell>
          <TableCell colSpan={1} />
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell>Total</TableCell>
          <TableCell>{formatCurrency(order.total)}</TableCell>
          <TableCell colSpan={1} />
          <TableCell colSpan={1} />
        </TableRow>
      </Table>
    </>
  )
}

export default OrderDetails
