import * as api from '../utils/api'

import {
  setTodaysInventory,
  updateInventoryForVariant,
} from '../store/actions/todays-inventory'

export const fetchInventory = (dispatch) => async (storeId) => {
  console.log(`Fetching today's inventory for store ${storeId}`)
  const { data } = await api.GET(`/p4/stores/${storeId}/inventory`)
  return dispatch(setTodaysInventory(storeId, data))
}

export const adjustInventory =
  (dispatch) => async (storeId, variantId, amount) => {
    return updateInventory(dispatch)(storeId, variantId, {
      adjust_quantity: amount,
    })
  }

export const markDoNotSell =
  (dispatch) => async (storeId, variantId, doNotSell) => {
    return updateInventory(dispatch)(storeId, variantId, {
      do_not_sell: doNotSell,
    })
  }

export const setQuantity =
  (dispatch) => async (storeId, variantId, quantity) => {
    return updateInventory(dispatch)(storeId, variantId, {
      quantity_available: quantity,
    })
  }

export const updateInventory =
  (dispatch) => async (storeId, variantId, data) => {
    console.log(`Updating inventory with data ${JSON.stringify(data)}`)
    const { data: inventory } = await api.PATCH(
      `/p4/stores/${storeId}/inventory/${variantId}`,
      data,
    )
    return dispatch(updateInventoryForVariant(storeId, variantId, inventory))
  }
