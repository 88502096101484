import AttributeSelector from '@components/shared/attribute-selector'
import React, { ReactElement, useState } from 'react'
import ResponsiveDialog from '@components/shared/responsive-dialog'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { ProductTypeType } from '@types'

const PreviewAddProductAttributeDialog = ({
  open,
  onCancel: handleCancel,
  onConfirm,
  productType,
}: Props): ReactElement => {
  const [selectedAttribute, setSelectedAttribute] = useState(0)

  const handleConfirm = () => {
    onConfirm(selectedAttribute)
  }

  return (
    <ResponsiveDialog open={open}>
      <DialogTitle>
        <Typography align='center'>Select New Attribute</Typography>
      </DialogTitle>
      <DialogContent>
        <AttributeSelector
          onChange={setSelectedAttribute}
          productType={productType}
          value={selectedAttribute}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button color='secondary' onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

export default PreviewAddProductAttributeDialog

type Props = {
  open: boolean
  onCancel: () => void
  onConfirm: (attributeId: number) => void
  productType: ProductTypeType
}
