import * as api from '@utils/api'
import AttributeForm from './attribute-form'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, AttributeValueType, ProductTypeType } from '@types'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { getAttributes, updateAttribute } from '@store/actions/attributes'
import { selectAttribute } from '@store/selectors/attributes'
import { setFlashMessage } from '@store/actions'
import { useConfirm } from '@components/shared/confirm-dialog'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const EditAttribute = (): ReactElement => {
  const confirm = useConfirm()
  const { attributeId } = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const attribute = useSelector(
    selectAttribute,
    attributeId ? parseInt(attributeId, 10) : undefined,
  )
  const [name, setName] = useState('')
  const [label, setLabel] = useState('')
  const [productType, setProductType] = useState<ProductTypeType>('beverage')
  const [attributeValues, setAttributeValues] = useState<AttributeValueType[]>(
    [],
  )
  const [isAdjective, setIsAdjective] = useState(false)
  const [includesGoods, setIncludesGoods] = useState(false)
  const [modifiedBy, setModifiedBy] = useState<null | number>(null)

  useEffect(() => {
    if (!attribute) dispatch(getAttributes())
  }, [attributeId])

  useEffect(() => {
    if (!attribute) return
    setName(attribute.name)
    setLabel(attribute.label)
    setProductType(attribute.product_type)
    setAttributeValues(attribute.attribute_values)
    setIsAdjective(attribute.is_adjective)
    setIncludesGoods(attribute.includes_goods)
    setModifiedBy(attribute.modifier_attribute_id)
  }, [attribute])

  if (!attribute) return <></>

  const handleAttributeValueChange =
    (i: number) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setAttributeValues(
        attributeValues.with(i, {
          ...attributeValues[i],
          name: value as string,
        }),
      )
    }

  const handleAttributeValueDelete =
    (i: number, attributeValueId: number) => () => {
      const continueDelete = () => {
        setAttributeValues(
          attributeValues.with(i, {
            ...attributeValues[i],
            deleted: true,
          }),
        )
      }

      if (attributeValueId < 0) {
        continueDelete()
        return
      }

      confirm({
        title: 'Be Careful',
        message:
          'Are you sure you want to never use this attribute value again?',
        onConfirm: continueDelete,
      })
    }

  const handleAttributeValueAdd = () => {
    setAttributeValues([
      ...attributeValues,
      {
        id: attributeValues.length * -1,
        name: '',
        is_void: false,
      },
    ])
  }

  const handleUpdate = async () => {
    try {
      await handleUpdateAttributeValues()
    } catch (error: any) {
      dispatch(setFlashMessage(error.message, 'error'))
      setAttributeValues(attribute.attribute_values)
      return
    }
    dispatch(
      updateAttribute({
        ...attribute,
        name,
        label,
        product_type: productType,
        is_adjective: isAdjective,
        includes_goods: includesGoods,
        modifier_attribute_id: modifiedBy,
      }),
    )
  }

  const handleUpdateAttributeValues = async () => {
    const updates = await Promise.all(
      attributeValues
        .map((attributeValue) => {
          if (attributeValue.deleted) {
            if (attributeValue.id < 0) return

            return api.DELETE(
              `/p4/preview/attributeValues/${attributeValue.id}`,
            )
          } else if (attributeValue.id < 0) {
            return api.POST(`/p4/preview/attributeValues`, {
              attribute_id: attribute.id,
              name: attributeValue.name,
              is_void: attributeValue.is_void,
            })
          } else {
            return api.PUT(`/p4/preview/attributeValues/${attributeValue.id}`, {
              name: attributeValue.name,
              is_void: attributeValue.is_void,
            })
          }
        })
        .filter((x) => x),
    )

    return updates
  }

  const handleIsVoidChange =
    (attributeValueId: number) => (_, checked: boolean) => {
      setAttributeValues(
        [...attributeValues].map((av) => ({
          ...av,
          is_void: checked && av.id == attributeValueId,
        })),
      )
    }

  return (
    <>
      <h1>{attribute.name}</h1>
      <AttributeForm
        includesGoods={includesGoods}
        isAdjective={isAdjective}
        label={label}
        modifiedBy={modifiedBy}
        name={name}
        onIncludesGoodsChange={setIncludesGoods}
        onIsAdjectiveChange={setIsAdjective}
        onLabelChange={setLabel}
        onModifiedByChange={setModifiedBy}
        onNameChange={setName}
        onProductTypeChange={setProductType}
        productType={productType}
      />
      <Paper sx={{ mt: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attribute Values</TableCell>
              <TableCell>Is Void Value (i.e. &rsquo;none&rsquo;)</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {attributeValues.map((attributeValue, i) => {
              if (attributeValue.deleted) return null
              return (
                <TableRow key={i}>
                  <TableCell>
                    <TextField
                      onChange={handleAttributeValueChange(i)}
                      value={attributeValue.name}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={attributeValue.is_void}
                          onChange={handleIsVoidChange(attributeValue.id)}
                        />
                      }
                      label=''
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={handleAttributeValueDelete(i, attributeValue.id)}
                    >
                      <Delete />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Button
                  color='secondary'
                  onClick={handleAttributeValueAdd}
                  variant='contained'
                >
                  Add Attribute Value
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          py: 2,
        }}
      >
        <Grid item>
          <Button color='secondary' onClick={handleUpdate} variant='contained'>
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default EditAttribute
