import PropTypes from 'prop-types'
import React, { useState } from 'react'

import ProductStoreForm from '../forms/product-store-form'
import ResponsiveDialog from '../responsive-dialog'
import Toolbar from '../toolbar'
import { Button, DialogActions } from '@mui/material'
import { useAllActions } from '@hooks'

function CreateProductStoreDialog(props) {
  const [productStoreData, setProductStoreData] = useState({})
  const { setFlashMessage } = useAllActions()

  return (
    <ResponsiveDialog
      loading={props.loading}
      onClose={props.onClose}
      open={props.open}
    >
      <Toolbar title='Add Product to Store' />
      <ProductStoreForm
        hideProduct={props.hideProduct}
        hideStore={props.hideStore}
        initialData={props.initialData}
        onChange={setProductStoreData}
        price={props.price}
      />
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
        <Button
          onClick={() => {
            if (
              productStoreData.store_id == null ||
              productStoreData.store_id == -1
            )
              return setFlashMessage('Must select a store.', 'error')

            if (
              productStoreData.product_id == null ||
              productStoreData.store_id == -1
            )
              return setFlashMessage('Must select a product', 'error')

            props.onConfirm(productStoreData)
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
}

ResponsiveDialog.propTypes = {
  /**
   * Initial data for the product store form; see product-store-form.js for its format.
   */
  initialData: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,

  /**
   * ProductStore information will be passed. If it's invalid, a message will be flashed
   * and this method will not be called.
   */
  onConfirm: PropTypes.func,
  price: PropTypes.number,
}

export default CreateProductStoreDialog
