export const ADD_USERS = 'ADD_USERS'
export const ADD_USERS_FAILURE = 'ADD_USERS_FAILURE'
export const SET_USER_COUNT = 'SET_USER_COUNT'
export const EDIT_USER = 'EDIT_USER'

export const UPDATE_CURRENT_USER_DATA = 'UPDATE_CURRENT_USER_DATA'
export const LOG_OUT = 'LOG_OUT'

export const SET_LOGGED_IN = 'SET_LOGGED_IN'

export const ADD_ORDERS = 'ADD_ORDERS'
export const SET_ORDER_COUNT = 'SET_ORDER_COUNT'

export const SET_ORDERS_FOR_USER = 'SET_ORDERS_FOR_USER'
export const SET_ORDER_COUNT_FOR_USER = 'SET_ORDER_COUNT_FOR_USER'

export const SET_ORDERS_FOR_STORE = 'SET_ORDERS_FOR_STORE'
export const SET_ORDER_COUNT_FOR_STORE = 'SET_ORDER_COUNT_FOR_STORE'

export const SET_HISTORY_FOR_ORDER = 'SET_HISTORY_FOR_ORDER'
export const SET_HISTORY_FOR_ORDER_FAILURE = 'SET_HISTORY_FOR_ORDER_FAILURE'

export const SET_CURRENTLY_DISPLAYED_USERS = 'SET_CURRENTLY_DISPLAYED_USERS'

export const ADD_STORES = 'ADD_STORES'
export const SET_STORES_COUNT = 'SET_STORES_COUNT'

export const ADD_PRODUCTS = 'ADD_PRODUCTS'

export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES'

export const UPDATE_INITIAL_INVENTORY = 'UPDATE_INITIAL_INVENTORY'
export const SET_INITIAL_INVENTORY = 'SET_INITIAL_INVENTORY'

export const SET_TODAYS_INVENTORY = 'SET_TODAYS_INVENTORY'
export const UPDATE_TODAYS_INVENTORY_FOR_VARIANT =
  'UPDATE_TODAYS_INVENTORY_FOR_VARIANT'

export const UPDATE_PRODUCTS_STORES = 'UPDATE_PRODUCTS_STORES'
export const REMOVE_PRODUCTS_STORES = 'REMOVE_PRODUCTS_STORES'
export const UPDATE_PRODUCTS_STORES_PRICE = 'UPDATE_PRODUCTS_STORES_PRICE'

export const UPDATE_SEARCH_SUGGESTIONS = 'UPDATE_SEARCH_SUGGESTIONS'

export const SET_HOURS_FOR_STORE = 'SET_HOURS_FOR_STORE'
export const SET_HOURS_FOR_STORE_FOR_DAY = 'SET_HOURS_FOR_STORE_FOR_DAY'

export const SET_STORE_HOUR_OVERRIDES = 'SET_STORE_HOUR_OVERRIDES'
export const ADD_STORE_HOUR_OVERRIDE = 'UPDATE_STORE_HOUR_OVERRIDE'

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'

export const SET_TIP_REPORT_START = 'SET_TIP_REPORT_START'
export const SET_TIP_REPORT_END = 'SET_TIP_REPORT_END'
export const SET_TIP_REPORT = 'SET_TIP_REPORT'

export const SET_STORE_ONLINE = 'SET_STORE_ONLINE'

export const ADD_VENDORS = 'ADD_VENDORS'
export const SET_VENDORS = 'SET_VENDORS'

export const SET_VENDOR_NUTRITION_LABELS = 'SET_VENDOR_NUTRITION_LABELS'
export const ADD_VENDOR_NUTRITION_LABELS = 'ADD_VENDOR_NUTRITION_LABELS'
export const DELETE_VENDOR_NUTRITION_LABELS = 'DELETE_VENDOR_NUTRITION_LABELS'

export const ADD_NUTRITION_LABELS = 'ADD_NUTRITION_LABELS'
export const DELETE_NUTRITION_LABELS = 'DELETE_NUTRITION_LABELS'

export const SET_EOD_FOR_DAY = 'SET_EOD_FOR_DAY'

export const ADD_RECOMMENDED_PRODUCTS = 'ADD_RECOMMENDED_PRODUCTS'
export const SET_RECOMMENDED_PRODUCTS = 'SET_RECOMMENDED_PRODUCTS'
export const SET_RECOMMENDED_PRODUCTS_FAILURE =
  'SET_RECOMMENDED_PRODUCTS_FAILURE'
export const DELETE_RECOMMENDED_PRODUCT = 'DELETE_RECOMMENDED_PRODUCT'
export const UPDATE_RECOMMENDED_PRODUCT_SUCCESS =
  'UPDATE_RECOMMENDED_PRODUCT_SUCCESS'
export const UPDATE_RECOMMENDED_PRODUCT_FAILURE =
  'UPDATE_RECOMMENDED_PRODUCT_FAILURE'
export const DELETE_RECOMMENDED_PRODUCT_SUCCESS =
  'DELETE_RECOMMENDED_PRODUCT_SUCCESS'
export const DELETE_RECOMMENDED_PRODUCT_FAILURE =
  'DELETE_RECOMMENDED_PRODUCT_FAILURE'
export const CREATE_RECOMMENDED_PRODUCT_SUCCESS =
  'CREATE_RECOMMENDED_PRODUCT_SUCCESS'
export const CREATE_RECOMMENDED_PRODUCT_FAILURE =
  'CREATE_RECOMMENDED_PRODUCT_FAILURE'

export const SET_ROLES = 'SET_ROLES'
export const SET_ROLE = 'SET_ROLES'
export const DELETE_ROLE = 'DELETE_ROLE'

export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_PERMISSION = 'SET_PERMISSIONS'
export const DELETE_PERMISSION = 'DELETE_PERMISSION'

export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'

export const SET_STORES = 'SET_STORES'
export const SET_STORE = 'SET_STORE'

// currently used for flash-message action
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE'
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE'

export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS'
export const SET_RECOMMENDATION = 'SET_RECOMMENDATION'

export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION'
export const UPDATE_RECOMMENDATION_FAILURE = 'UPDATE_RECOMMENDATION_FAILURE'

export const DELETE_RECOMMENDATION = 'DELETE_RECOMMENDATION'
export const DELETE_RECOMMENDATION_FAILURE = 'DELETE_RECOMMENDATION_FAILURE'

export const CREATE_RECOMMENDATION = 'CREATE_RECOMMENDATION'
export const CREATE_RECOMMENDATION_FAILURE = 'CREATE_RECOMMENDATION_FAILURE'

export const SET_ATTRIBUTE_VALUES = 'SET_ATTRIBUTE_VALUES'
export const SET_ATTRIBUTE_VALUES_FAILURE = 'SET_ATTRIBUTE_VALUES_FAILURE'

export const UPDATE_ATTRIBUTE_VALUES = 'UPDATE_ATTRIBUTE_VALUES'
export const UPDATE_ATTRIBUTE_VALUES_FAILURE = 'UPDATE_ATTRIBUTE_VALUES_FAILURE'
export const ADD_DEFAULT_PRODUCTS = 'ADD_DEFAULT_PRODUCTS'

export const SET_TAG = 'SET_TAG'
export const SET_TAG_FAILURE = 'SET_TAG_FAILURE'

export const CREATE_TAG = 'CREATE_TAG'
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE'

export const UPDATE_TAG = 'UPDATE_TAG'
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE'

export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'

export const SAVE_EMPLOYEE = 'SAVE_EMPLOYEE'
export const SAVE_EMPLOYEE_FAILURE = 'SAVE_EMPLOYEE_FAILURE'

export const SET_VENDOR_PRODUCTS = 'SET_VENDOR_PRODUCTS'
export const SET_VENDOR_PRODUCTS_FAILURE = 'SET_VENDOR_PRODUCTS_FAILURE'

export const SET_VENDOR_PRODUCT = 'SET_VENDOR_PRODUCT'
export const SET_VENDOR_PRODUCT_FAILURE = 'SET_VENDOR_PRODUCT_FAILURE'

export const SET_TAX_RATES = 'SET_TAX_RATES'
export const SET_TAX_RATES_FAILURE = 'SET_TAX_RATES_FAILURE'
export const UPDATED_TAX_RATES = 'UPDATED_TAX_RATES'
export const UPDATED_TAX_RATES_FAILURE = 'UPDATED_TAX_RATES_FAILURE'

export const SET_SURCHARGES = 'SET_SURCHARGES'
export const SET_SURCHARGES_FAILURE = 'SET_SURCHARGES_FAILURE'

export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS'
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE'

export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS'
export const GET_PROMOTION_FAILURE = 'GET_PROMOTION_FAILURE'

export const GET_PROMOTION_TYPES_SUCCESS = 'GET_PROMOTION_TYPES_SUCCESS'
export const GET_PROMOTION_TYPES_FAILURE = 'GET_PROMOTION_TYPES_FAILURE'

export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS'
export const UPDATE_PROMOTION_FAILURE = 'UPDATE_PROMOTION_FAILURE'

export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS'
export const CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE'

export const ARCHIVE_PROMOTION_SUCCESS = 'ARCHIVE_PROMOTION_SUCCESS'
export const ARCHIVE_PROMOTION_FAILURE = 'ARCHIVE_PROMOTION_FAILURE'

export const REFUNDED_PRODUCTS_SUCCESS = 'REFUNDED_PRODUCTS_SUCCESS'
export const REFUNDED_PRODUCTS_FAILURE = 'REFUNDED_PRODUCTS_FAILURE'

export const RESEND_RECEIPT_SUCCESS = 'RESEND_RECEIPT_SUCCESS'
export const RESEND_RECEIPT_FAILURE = 'RESEND_RECEIPT_FAILURE'

export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS'
export const UPDATE_MENU_FAILURE = 'UPDATE_MENU_FAILURE'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'

export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const GET_ATTRIBUTES = 'GET_ATTRIBUTES'
export const GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE'
export const SET_ATTRIBUTE = 'SET_ATTRIBUTE'
export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE'
export const CREATE_ATTRIBUTE_FAILURE = 'CREATE_ATTRIBUTE_FAILURE'
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'
export const UPDATE_ATTRIBUTE_FAILURE = 'UPDATE_ATTRIBUTE_FAILURE'
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
export const DELETE_ATTRIBUTE_FAILURE = 'DELETE_ATTRIBUTE_FAILURE'

export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES'
export const GET_PRODUCT_ATTRIBUTES_FAILURE = 'GET_PRODUCT_ATTRIBUTES_FAILURE'
export const SET_PRODUCT_ATTRIBUTE = 'SET_PRODUCT_ATTRIBUTE'
export const CREATE_PRODUCT_ATTRIBUTE = 'CREATE_PRODUCT_ATTRIBUTE'
export const CREATE_PRODUCT_ATTRIBUTE_FAILURE =
  'CREATE_PRODUCT_ATTRIBUTE_FAILURE'
export const UPDATE_PRODUCT_ATTRIBUTE = 'UPDATE_PRODUCT_ATTRIBUTE'
export const UPDATE_PRODUCT_ATTRIBUTE_FAILURE =
  'UPDATE_PRODUCT_ATTRIBUTE_FAILURE'
export const DELETE_PRODUCT_ATTRIBUTE = 'DELETE_PRODUCT_ATTRIBUTE'
export const DELETE_PRODUCT_ATTRIBUTE_FAILURE =
  'DELETE_PRODUCT_ATTRIBUTE_FAILURE'
export const CREATE_PRODUCT_ATTRIBUTE_VALUE = 'CREATE_PRODUCT_ATTRIBUTE_VALUE'
export const CREATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE =
  'CREATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE'
export const DELETE_PRODUCT_ATTRIBUTE_VALUE = 'DELETE_PRODUCT_ATTRIBUTE_VALUE'
export const DELETE_PRODUCT_ATTRIBUTE_VALUE_FAILURE =
  'DELETE_PRODUCT_ATTRIBUTE_VALUE_FAILURE'
export const UPDATE_PRODUCT_ATTRIBUTE_VALUE = 'UPDATE_PRODUCT_ATTRIBUTE_VALUE'
export const UPDATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE =
  'UPDATE_PRODUCT_ATTRIBUTE_VALUE_FAILURE'
export const CLONE_PRODUCT_ATTRIBUTES = 'CLONE_PRODUCT_ATTRIBUTES'
export const CLONE_PRODUCT_ATTRIBUTES_FAILURE =
  'CLONE_PRODUCT_ATTRIBUTES_FAILURE'

export const SET_VARIANT = 'SET_VARIANT'
export const GET_PRODUCT_VARIANTS = 'GET_PRODUCT_VARIANTS'
export const GET_PRODUCT_VARIANTS_FAILURE = 'GET_PRODUCT_VARIANTS_FAILURE'
export const CREATE_VARIANT = 'CREATE_VARIANT'
export const CREATE_VARIANT_FAILURE = 'CREATE_VARIANT_FAILURE'
export const UPDATE_VARIANT = 'UPDATE_VARIANT'
export const UPDATE_VARIANT_FAILURE = 'UPDATE_VARIANT_FAILURE'
export const DELETE_VARIANT = 'DELETE_VARIANT'
export const DELETE_VARIANT_FAILURE = 'DELETE_VARIANT_FAILURE'

export const GET_PROMOTIONAL_CATEGORIES = 'GET_PROMOTIONAL_CATEGORIES'
export const GET_PROMOTIONAL_CATEGORIES_FAILURE =
  'GET_PROMOTIONAL_CATEGORIES_FAILURE'
export const UPDATE_PROMOTIONAL_CATEGORY = 'UPDATE_PROMOTIONAL_CATEGORY'
export const UPDATE_PROMOTIONAL_CATEGORY_FAILURE =
  'UPDATE_PROMOTIONAL_CATEGORY_FAILURE'
export const CREATE_PROMOTIONAL_CATEGORY = 'CREATE_PROMOTIONAL_CATEGORY'
export const CREATE_PROMOTIONAL_CATEGORY_FAILURE =
  'CREATE_PROMOTIONAL_CATEGORY_FAILURE'
export const DELETE_PROMOTIONAL_CATEGORY = 'DELETE_PROMOTIONAL_CATEGORY'
export const DELETE_PROMOTIONAL_CATEGORY_FAILURE =
  'DELETE_PROMOTIONAL_CATEGORY_FAILURE'
