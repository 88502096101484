import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Form from '../form'
import ProductSelector from '../product-selector'
import StoreSelector from '../store-selector'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  form: {
    paddingTop: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

function ProductStoreForm(props) {
  const { classes } = useStyles()
  const initialData = props.initialData || {}
  const [storeId, setStoreId] = useState(initialData.storeId || -1)
  const [productId, setProductId] = useState(initialData.productId || -1)

  useEffect(() => {
    props.onChange({
      product_id: productId,
      store_id: storeId,
    })
  }, [storeId, productId])

  return (
    <Form className={classes.form} paper={false}>
      {!props.hideStore && (
        <StoreSelector
          className={classes.marginBottom}
          containerProps={{ fullWidth: true }}
          onChange={setStoreId}
          value={storeId}
        />
      )}
      {!props.hideProduct && (
        <ProductSelector
          className={classes.marginBottom}
          containerProps={{ fullWidth: true }}
          onChange={setProductId}
          value={productId}
        />
      )}
    </Form>
  )
}

ProductStoreForm.propTypes = {
  /**
   * Initial data accepts keys 'storeId', 'productId',
   */
  initialData: PropTypes.object,
  onChange: PropTypes.func,
}

export default connect()(ProductStoreForm)
