import Discounted from './forms/discounted'
import ErrorBoundary from '@components/shared/error-boundary'
import FreeTriggeredSmallCup from './forms/free-triggered-small-cup'
import NumberOfBags from './forms/number-of-bags'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import Roles from './roles'
import SizeUp from './forms/size-up'
import Stores from './stores'
import Toolbar from '@components/shared/toolbar'
import ToolbarButton from '@components/shared/toolbar-button'
import TriggeredDiscount from './forms/triggered-discount'
import Users from './users'
import hasPerms from '@utils/has-permission'
import useSelector from '@hooks/use-selector'
import {
  AppDispatch,
  PromotionType,
  PromotionUserType,
  PromotionalCategoryType,
} from '@types'
import { makeStyles } from 'tss-react/mui'

import Combo from './forms/combo'
import NetsuiteAccountSelect from '../products/netsuite-account-select'
import PromotionalCategoriesCheckboxes from '@components/shared/promotional-categories-checkboxes'
import WholeOrder from './forms/whole-order'
import dayjs, { Dayjs } from 'dayjs'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { Frequency, RRule, Weekday, WeekdayStr } from 'rrule'
import {
  archivePromotion,
  createPromotion,
  getPromotion,
  updatePromotion,
} from '@store/actions/promotions'
import { formatBackend, getTomorrow } from '@utils/date-utils'
import { getPromotionTypes } from '@store/actions/promotion-types'
import { selectPromotion } from '@store/selectors/promotions'
import { selectPromotionTypes } from '@store/selectors/promotion-types'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  configurationWrapper: {
    padding: theme.spacing(2),
  },
  sectionMargin: {
    marginTop: theme.spacing(2),
  },
  pickerContainer: {
    marginTop: theme.spacing(1),
  },
  startDatePicker: {
    marginRight: theme.spacing(2),
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    margin: theme.spacing(2),
  },
  actionButton: {
    marginLeft: 'auto',
  },
  nameInput: {
    marginRight: theme.spacing(2),
  },
  promoSelect: {
    minWidth: 250,
    marginRight: 20,
  },
  accountSelect: {
    minWidth: 250,
  },
  codeInput: {
    minWidth: 300,
  },
  promotionalCategoriesContainer: {
    paddingBottom: theme.spacing(2),
  },
}))

const Promotion = (): ReactElement => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isCreatePromotion, setIsCreatePromotion] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const promotion = useSelector(selectPromotion, id)
  const promotionTypes = useSelector(selectPromotionTypes)

  const [promoName, setPromoName] = useState('')
  const [promoType, setPromoType] = useState('-1')
  const tomorrow = formatBackend(getTomorrow())
  const [promoStart, setPromoStart] = useState(tomorrow)
  const [promoEnd, setPromoEnd] = useState(tomorrow)
  const [activationCode, setActivationCode] = useState<string | null>(null)
  const [promoEndForever, setPromoEndForever] = useState(false)
  const [configuration, setConfiguration] = useState<Record<string, any>>({})
  const [editing, setEditing] = useState(false)
  const [storeIds, setStoreIds] = useState<number[]>([])
  const [roleIds, setRoleIds] = useState<number[]>([])
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
  const [promoAttachedTo, setPromoAttachedTo] = useState('stores')
  const [limitUses, setLimitUses] = useState<number>(-1)
  const [account, setAccount] = useState('50500')
  const [users, setUsers] = useState<PromotionUserType[]>([])
  const [usersHasChanged, setUsersHasChanged] = useState(false)
  const [startTime, setStartTime] = useState('00:00:00')
  const [endTime, setEndTime] = useState('23:59:59')
  const [rRule, setRRule] = useState<{
    frequency: Frequency
    interval: number
    byWeekDay: WeekdayStr[]
  }>({
    frequency: RRule.WEEKLY,
    interval: 1,
    byWeekDay: [],
  })
  const [showRepeats, setShowRepeats] = useState(false)
  const [intervalFieldValue, setIntervalFieldValue] = useState('1')
  const [promotionalCategoryExclusions, setPromotionalCategoryExclusions] =
    useState<PromotionalCategoryType[]>([])

  const canEdit = hasPerms('promotions:update')
  const promotionExists = Boolean(promotion)

  useEffect(() => {
    dispatch(getPromotionTypes())
    if (id === 'create') {
      setIsCreatePromotion(true)
      setEditing(true)
    } else if (!promotionExists) {
      dispatch(getPromotion(id as string))
    }
  }, [id, promotionExists])

  useEffect(() => {
    if (!promotion) return
    setConfiguration(promotion.configuration)
    setPromoName(promotion.name)
    setPromoType(promotion.type)
    setPromoStart(promotion.start_date)
    setActivationCode(promotion.activation_code)
    setPromoEnd(promotion.end_date)
    if (new Date(promotion.end_date).getFullYear() >= 9999) {
      setPromoEndForever(true)
    }
    const storeIds = promotion.stores.map((s) => s.id)
    setPromoAttachedTo(storeIds.length ? 'stores' : 'roles')
    setStoreIds(storeIds)
    setRoleIds(promotion.roles.map((s) => s.id))
    setLimitUses(promotion.limit_uses ?? -1)
    setUsers(promotion.users)
    setAccount(promotion.account)
    setPromotionalCategoryExclusions(promotion.promotional_category_exclusions)

    if (promotion.repeats) {
      const rule = RRule.fromString(promotion.repeats)
      setShowRepeats(true)
      setIntervalFieldValue(rule.options.interval.toString())
      setRRule({
        frequency: rule.options.freq,
        interval: rule.options.interval,
        byWeekDay: rule.options.byweekday?.length
          ? rule.options.byweekday.map(
              (d) => new Weekday(d).toString() as WeekdayStr,
            )
          : [],
      })
    }
  }, [promotion])

  const exampleOccurrences = useMemo(() => {
    const today = new Date()
    const starts = new Date(promoStart + 'T00:00:00')
    const ends = promoEndForever ? null : new Date(promoEnd + 'T23:59:59')
    const oneYearFromToday = new Date()
    oneYearFromToday.setFullYear(today.getFullYear() + 1)

    const rule = new RRule({
      freq: rRule.frequency,
      interval: rRule.interval,
      byweekday: rRule.byWeekDay.length
        ? rRule.byWeekDay.map((d) => RRule[d])
        : null,
      dtstart: starts,
      until: ends,
    })

    const dates = rule.between(starts, oneYearFromToday, true)
    return dates.splice(0, 5)
  }, [rRule, promoStart, promoEnd, promoEndForever])

  if (!promotion && !isCreatePromotion) return <></>

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    setPromoType(e.target.value)
    setConfiguration({})
  }

  const handleStartChange = (date) => {
    console.log('handle start change')
    if (date === 'Invalid Date') return
    setPromoStart(formatBackend(date))
  }

  const handleEndChange = (date) => {
    if (date === 'Invalid Date') return
    setPromoEnd(formatBackend(date))
  }

  const handleStartTimeChange = (date: Dayjs | null) => {
    if (!date) return
    setStartTime(date.format('HH:mm:00'))
  }

  const handleEndTimeChange = (date: Dayjs | null) => {
    if (!date) return
    const time = date.format('HH:mm:00')
    setEndTime(time == '00:00:00' ? '23:59:59' : time)
  }

  const handleSave = () => {
    const repeatsRule = new RRule({
      freq: rRule.frequency,
      interval: rRule.interval,
      byweekday: rRule.byWeekDay.length
        ? rRule.byWeekDay.map((d) => RRule[d])
        : null,
    })

    const params = {
      type: promoType,
      start_date: promoStart,
      end_date: promoEnd,
      start_time: startTime,
      end_time: endTime,
      configuration,
      stores: storeIds.map((x) => ({ id: x })),
      roles: roleIds.map((x) => ({ id: x })),
      name: promoName,
      activation_code: activationCode,
      limit_uses: limitUses === -1 ? null : limitUses,
      users: usersHasChanged ? users : undefined,
      repeats: showRepeats ? repeatsRule.toString() : null,
      account,
      promotional_category_exclusions: promotionalCategoryExclusions,
    }
    const action =
      !isCreatePromotion && promotion
        ? updatePromotion({ id: promotion.id, ...params })
        : createPromotion(params)
    dispatch(action).then((p: PromotionType) => {
      if (isCreatePromotion) {
        setIsCreatePromotion(false)
        navigate(`/promotions/${p.id}`)
      }
      setEditing(false)
    })
  }

  const handleEdit = () => {
    setEditing(true)
  }

  const handleConfigurationChange = (configuration: Record<string, any>) => {
    setConfiguration(configuration)
  }

  const handleNameChange = (e: any) => {
    setPromoName(e.target.value)
  }

  const handleActivationCodeChange = (e: any) => {
    let value = e.target.value
    if (!value) value = null
    setActivationCode(value)
  }

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false)
  }

  const handleArchive = () => {
    setArchiveDialogOpen(true)
  }

  const archive = () => {
    if (!promotion) return
    dispatch(archivePromotion(promotion.id))
    navigate('/promotions')
  }

  const handleEndForeverChanged = (_, isChecked) => {
    setPromoEndForever(isChecked)
    if (isChecked) {
      // match infinity and/or max value
      setPromoEnd('9999-12-31 23:59:59')
    } else {
      setPromoEnd(tomorrow)
    }
  }

  const handleChangePromoAttachedTo = (_, value) => {
    if (value === 'stores') {
      setRoleIds([])
    } else {
      setStoreIds([])
    }
    setPromoAttachedTo(value)
  }

  const handleLimitUsesChange = (e: SelectChangeEvent) => {
    setLimitUses(parseInt(e.target.value, 10))
  }

  const handleAccountChange = (account: number) => {
    setAccount(account.toString())
  }

  const handleAddUser = (user: PromotionUserType) => {
    setUsers((oldUsers) => [...oldUsers, user])
    setUsersHasChanged(true)
  }

  const handleReplaceUsers = (users: PromotionUserType[]) => {
    setUsers(users)
    setUsersHasChanged(true)
  }

  const handleClearUsers = () => {
    setUsers([])
    setUsersHasChanged(true)
  }

  const handleRemoveUser = (user: PromotionUserType) => {
    setUsers((oldUsers) => oldUsers.filter((u) => u.id != user.id))
    setUsersHasChanged(true)
  }

  const handleFrequencyChange = (e: any) => {
    const newRRule: { frequency: Frequency; byWeekDay?: WeekdayStr[] } = {
      frequency: parseInt(e.target.value, 10),
    }
    if (newRRule.frequency != RRule.WEEKLY) {
      newRRule.byWeekDay = []
    }
    setRRule((oldRRule) => ({
      ...oldRRule,
      ...newRRule,
    }))
  }

  const handleIntervalChange = (e: any) => {
    const newValue = parseInt(e.target.value, 10)
    if (isNaN(newValue)) {
      setIntervalFieldValue('')
      return
    }
    const newInterval = newValue < 1 ? 1 : newValue
    setIntervalFieldValue(newInterval.toString())
    setRRule((oldRRule) => ({
      ...oldRRule,
      interval: newInterval,
    }))
  }

  const handleByDayChange = (day: WeekdayStr) => (_, isChecked: boolean) => {
    setRRule((oldRRule) => {
      let days: WeekdayStr[] = []
      if (isChecked) {
        days = [...oldRRule.byWeekDay, day]
      } else {
        days = oldRRule.byWeekDay.filter((d) => d !== day)
      }
      return {
        ...oldRRule,
        byWeekDay: days,
      }
    })
  }

  const handleShowRepeatsChanged = (_, isChecked) => {
    if (isChecked && promoStart == '0001-01-01') {
      // handles legacy start format
      setPromoStart(formatBackend(new Date()))
    }
    setShowRepeats(isChecked)
  }

  return (
    <>
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        onClose={handleArchiveDialogClose}
        open={archiveDialogOpen}
      >
        <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to archive this promotion? It will no longer
            appear in the admin panel.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color='primary' onClick={handleArchiveDialogClose}>
            Nevermind
          </Button>
          <Button color='primary' onClick={archive}>
            Confirm Archive
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorBoundary>
        <Paper>
          <div className={classes.actionButtonContainer}>
            <Toolbar title={promoName || 'Create Promotion'} />
            {canEdit && (
              <>
                <ToolbarButton
                  className={classes.actionButton}
                  onClick={() => (editing ? handleSave() : handleEdit())}
                  title={editing ? 'Save' : 'Edit'}
                />
                {!isCreatePromotion && (
                  <ToolbarButton onClick={handleArchive} title={'Archive'} />
                )}
              </>
            )}
          </div>
          <Divider />
          <div className={classes.configurationWrapper}>
            <TextField
              className={classes.nameInput}
              disabled={!editing}
              label='Name (internal)'
              onChange={handleNameChange}
              value={promoName}
            />
            <FormControl className={classes.promoSelect}>
              <InputLabel id='promotion-type-select-label'>
                Promotion Type
              </InputLabel>
              <Select
                disabled={!editing}
                labelId='promotion-type-select-label'
                onChange={handleTypeChange}
                value={promoType}
              >
                <MenuItem key={-1} value={-1}>
                  <em>Select Type</em>
                </MenuItem>
                {Object.values(promotionTypes).map((promotionType, i) => (
                  <MenuItem key={i} value={promotionType.type}>
                    {promotionType.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.promoSelect}>
              <InputLabel id='promotion-limit-select-label'>
                Number of Uses Per Customer
              </InputLabel>
              <Select
                disabled={!editing}
                labelId='promotion-limit-select-label'
                onChange={handleLimitUsesChange}
                value={String(limitUses)}
              >
                <MenuItem key={-1} value={-1}>
                  Unlimited
                </MenuItem>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                  <MenuItem key={number} value={number}>
                    {number} Use{number > 1 ? 's' : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <NetsuiteAccountSelect
              disabled={!editing}
              formControlProps={{ className: classes.accountSelect }}
              onChange={handleAccountChange}
              value={account}
            />

            <div>
              {!!promoType && promotionTypes[promoType] ? (
                <h3>{promotionTypes[promoType].description}</h3>
              ) : (
                <h3>Select a promotion type to begin configuration.</h3>
              )}
            </div>

            {promoType != 'ComboDiscountPromotion' && (
              <div className={classes.promotionalCategoriesContainer}>
                <strong>
                  Applies to products with these promotional categories.
                </strong>
                <div>
                  <PromotionalCategoriesCheckboxes
                    disabled={!editing}
                    inverseCheckedValues
                    onChange={setPromotionalCategoryExclusions}
                    value={promotionalCategoryExclusions}
                  />
                </div>
              </div>
            )}

            <div>
              <TextField
                className={classes.codeInput}
                disabled={!editing}
                label='Promotion Activation Code'
                onChange={handleActivationCodeChange}
                value={activationCode}
              />

              <p>
                <strong>
                  Setting a promotion activation code is completely optional.
                </strong>
                &nbsp; If you use a promotion code for this promotion it will
                only be applied when a customer enters the matching code. If
                left blank the promotion applies to all customers who are
                elgibile for the promotion.
              </p>
            </div>

            <div className={classes.pickerContainer}>
              <DatePicker
                className={classes.startDatePicker}
                disabled={!editing}
                format='M/D/YY'
                label='Start Date'
                onChange={handleStartChange}
                value={dayjs(promoStart + 'T00:00:00')}
              />
              {!promoEndForever && (
                <DatePicker
                  disabled={!editing}
                  format='M/D/YY'
                  label='End Date'
                  onChange={handleEndChange}
                  value={dayjs(promoEnd + 'T00:00:00')}
                />
              )}
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={promoEndForever}
                    disabled={!editing}
                    onChange={handleEndForeverChanged}
                  />
                }
                label='Never Ends'
              />
            </div>
            <div className={classes.pickerContainer}>
              <TimePicker
                className={classes.startDatePicker}
                disabled={!editing}
                label='Start Time'
                onChange={handleStartTimeChange}
                value={dayjs('1970-01-01T' + startTime)}
              />
              <TimePicker
                disabled={!editing}
                label='End Time'
                onChange={handleEndTimeChange}
                value={dayjs('1970-01-01T' + endTime)}
              />
            </div>
            <p>
              Promotions will apply for the times between Start Time and End
              Time beginning on the Start Date and ending at the End Date. The
              promotion will apply every day unless a repetition rule is
              created.
            </p>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRepeats}
                    disabled={!editing}
                    onChange={handleShowRepeatsChanged}
                    value={showRepeats}
                  />
                }
                label='Apply a repetition rule'
              />
            </div>
            {showRepeats && (
              <div className={classes.pickerContainer}>
                <p>Repeat every</p>
                <Input
                  disabled={!editing}
                  inputProps={{ min: 1 }}
                  onChange={handleIntervalChange}
                  type='number'
                  value={intervalFieldValue}
                />
                <Select
                  disabled={!editing}
                  onChange={handleFrequencyChange}
                  value={rRule.frequency}
                >
                  <MenuItem value={RRule.DAILY}>days</MenuItem>
                  <MenuItem value={RRule.WEEKLY}>weeks</MenuItem>
                  <MenuItem value={RRule.MONTHLY}>months</MenuItem>
                </Select>
                {rRule.frequency === RRule.WEEKLY && (
                  <div>
                    <p>Repeat on</p>
                    {[
                      ['SU', 'Sunday'],
                      ['MO', 'Monday'],
                      ['TU', 'Tuesday'],
                      ['WE', 'Wednesday'],
                      ['TH', 'Thursday'],
                      ['FR', 'Friday'],
                      ['SA', 'Saturday'],
                    ].map(([abbr, day]) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rRule.byWeekDay.includes(
                              abbr as WeekdayStr,
                            )}
                            disabled={!editing}
                            onChange={handleByDayChange(abbr as WeekdayStr)}
                            value={abbr}
                          />
                        }
                        key={abbr}
                        label={day as string}
                      />
                    ))}
                  </div>
                )}
                <p>
                  Some example dates to make sure we&apos;ve got this right:
                </p>
                <ul>
                  {exampleOccurrences.map((date, i) => (
                    <li key={i}>{date.toDateString()}</li>
                  ))}
                </ul>
              </div>
            )}

            <FormControl>
              <FormLabel>Promotion Applies To</FormLabel>
              <RadioGroup
                onChange={handleChangePromoAttachedTo}
                value={promoAttachedTo}
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={!editing}
                  label='Stores'
                  value='stores'
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={!editing}
                  label='Roles'
                  value='roles'
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Paper>

        {(() => {
          switch (promoType) {
            case 'FreeTriggeredSmallCupPromotion':
              return (
                <FreeTriggeredSmallCup
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'SizeUpPromotion':
              return (
                <SizeUp
                  className={classes.sectionMargin}
                  cohorts={
                    promotionTypes[promoType]
                      ? promotionTypes[promoType].cohorts
                      : {}
                  }
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'TriggeredDiscountPromotion':
              return (
                <TriggeredDiscount
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'FreeEmployeeBagsPromotion':
              return (
                <NumberOfBags
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'DiscountPromotion':
            case 'FirstOrderAtStoreDiscountPromotion':
            case 'NewCustomerDiscountPromotion':
              return (
                <Discounted
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'WholeOrderDiscountPromotion':
              return (
                <WholeOrder
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
            case 'ComboDiscountPromotion':
              return (
                <Combo
                  className={classes.sectionMargin}
                  editing={editing}
                  onConfigurationChange={handleConfigurationChange}
                  promotion={promotion}
                />
              )
          }
        })()}

        <Paper className={classes.sectionMargin}>
          {promoAttachedTo === 'stores' && (
            <Stores
              editing={editing}
              onChange={setStoreIds}
              storeIds={storeIds}
            />
          )}
          {promoAttachedTo === 'roles' && (
            <Roles editing={editing} onChange={setRoleIds} roleIds={roleIds} />
          )}
        </Paper>

        <Paper className={classes.sectionMargin}>
          <Users
            editing={editing}
            onAddUser={handleAddUser}
            onClearUsers={handleClearUsers}
            onRemoveUser={handleRemoveUser}
            onReplaceUsers={handleReplaceUsers}
            users={users}
          />
        </Paper>
      </ErrorBoundary>
    </>
  )
}

export default Promotion
