import {
  SET_TODAYS_INVENTORY,
  UPDATE_TODAYS_INVENTORY_FOR_VARIANT,
} from '../action-list'

export const setTodaysInventory = (storeId, inventory) => ({
  type: SET_TODAYS_INVENTORY,
  storeId,
  inventory,
})

export const updateInventoryForVariant = (storeId, variantId, inventory) => ({
  type: UPDATE_TODAYS_INVENTORY_FOR_VARIANT,
  storeId,
  variantId,
  inventory,
})
