import React, { ReactElement, useEffect } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch } from '@types'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { getPromotionalCategories } from '@store/actions/promotional-categories'
import { selectPromotionalCategories } from '@store/selectors/promotional-categories'
import { useDispatch } from 'react-redux'

const PromotionalCategoriesSelect = ({
  onChange,
  value,
  disabled = false,
  nullLabel,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const promotionalCategories = useSelector(selectPromotionalCategories)

  useEffect(() => {
    if (!promotionalCategories.length) {
      dispatch(getPromotionalCategories())
    }
  }, [])

  const handleOnChange = ({ target: { value } }: SelectChangeEvent<number>) => {
    if (nullLabel && value == -1) {
      onChange(null)
      return
    }
    onChange(value as number)
  }

  if (!promotionalCategories.length) return <></>

  return (
    <Select
      disabled={disabled}
      onChange={handleOnChange}
      value={nullLabel ? (value ?? -1) : (value ?? '')}
    >
      {nullLabel && (
        <MenuItem key={-1} value={-1}>
          {nullLabel}
        </MenuItem>
      )}
      {promotionalCategories.map((category) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default PromotionalCategoriesSelect

type NonNullableProps = {
  onChange: (selected: number) => void
  value: number | null
  nullLabel?: never
  disabled?: boolean
}

type NullableProps = {
  onChange: (selected: number | null) => void
  value: number | null
  nullLabel: string
  disabled?: boolean
}

type Props = NonNullableProps | NullableProps
