import MaterialTable, { MTableToolbar } from '@material-table/core'
import PromotionalCategoriesSelect from '@components/shared/promotional-categories-select'
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { PromotionType } from '@types'
import { makeStyles } from 'tss-react/mui'

const Combo = ({
  className,
  promotion,
  editing,
  onConfigurationChange,
}: PropsType): ReactElement => {
  const { classes } = useStyles()
  const [
    discountedItemPromotionalCategoryId,
    setDiscountedItemPromotionalCategoryId,
  ] = useState<number | null>(null)
  const [
    qualifyingPromotionalCategoryIds,
    setQualifyingPromotionalCategoryIds,
  ] = useState<number[]>([])
  const [percentageOff, setPercentageOff] = useState<number | null>(null)
  const [centsOff, setCentsOff] = useState<number | null>(null)
  const [discountMode, setDiscountMode] = useState<'percentage' | 'cents'>(
    'percentage',
  )

  const currentConfig = promotion ? promotion.configuration : {}
  const config: ConfigurationType = {
    cents_off: 0,
    percentage_off: 0,
    discounted_promotional_category_id: null,
    qualifying_promotional_category_ids: [],
    ...currentConfig,
  }

  const resetConfiguration = () => {
    setCentsOff(config.cents_off ? config.cents_off : null)
    setPercentageOff(config.percentage_off ? config.percentage_off : null)
    setDiscountedItemPromotionalCategoryId(
      config.discounted_promotional_category_id,
    )
    setQualifyingPromotionalCategoryIds(
      config.qualifying_promotional_category_ids,
    )
    setDiscountMode(config.cents_off ? 'cents' : 'percentage')
  }

  useEffect(() => {
    resetConfiguration()
  }, [])

  useEffect(() => {
    if (!promotion) return

    resetConfiguration()
  }, [promotion])

  useEffect(() => {
    prepareConfiguration()
  }, [
    centsOff,
    percentageOff,
    discountedItemPromotionalCategoryId,
    qualifyingPromotionalCategoryIds,
  ])

  const qualifyingTableData = useMemo(() => {
    return qualifyingPromotionalCategoryIds.map((promotionalCategoryId) => ({
      promotionalCategoryId,
    }))
  }, [qualifyingPromotionalCategoryIds])

  const prepareConfiguration = () => {
    const newConfig: any = {
      discounted_promotional_category_id: discountedItemPromotionalCategoryId,
      qualifying_promotional_category_ids: qualifyingPromotionalCategoryIds,
    }

    if (discountMode == 'percentage') {
      newConfig.percentage_off = percentageOff
    } else {
      newConfig.cents_off = centsOff
    }

    onConfigurationChange(newConfig)
  }

  const handleCentsOffChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    const newValue = value ? parseInt(value, 10) : 0
    setCentsOff(newValue)
  }

  const handleChangeDiscountMode = (_, value) => {
    setDiscountMode(value as 'percentage' | 'cents')
    if (value == 'cents') {
      setPercentageOff(null)
      setCentsOff(0)
      return
    }

    setCentsOff(null)
    setPercentageOff(0.0)
  }

  const handlePercentageOffChange = (e: any) => {
    let value = e.target.value
    value = value ? parseFloat(value) : 0.0
    if (value > 100) value = 100.0
    setPercentageOff(value)
  }

  const discountAmountInput = {
    percentage: () => (
      <>
        <TextField
          disabled={!editing}
          label='Percentage Off'
          onChange={handlePercentageOffChange}
          value={percentageOff}
        />

        <p>
          Only the discounted item will get this percentend off its base price.
        </p>
      </>
    ),
    cents: () => (
      <>
        <TextField
          disabled={!editing}
          label='Cents Off'
          onChange={handleCentsOffChange}
          value={centsOff}
        />

        <p>Only the discounted item will get these cents off its base price.</p>
      </>
    ),
  }

  const handleDiscountItemChange = (promotionalCategoryId: number | null) => {
    setDiscountedItemPromotionalCategoryId(promotionalCategoryId)
  }

  const handleCreateQualifier = async ({ promotionalCategoryId }) => {
    setQualifyingPromotionalCategoryIds([
      ...qualifyingPromotionalCategoryIds,
      promotionalCategoryId,
    ])
  }

  const handleUpdateQualifier = async (newData, oldData) => {
    const { promotionalCategoryId: newPromotionalCategoryId } = newData
    const { promotionalCategoryId: oldPromotionalCategoryId } = oldData
    const newQualifiers = [...qualifyingPromotionalCategoryIds]
    newQualifiers.splice(
      newQualifiers.findIndex((id) => id == oldPromotionalCategoryId),
      1,
      newPromotionalCategoryId,
    )
    setQualifyingPromotionalCategoryIds(newQualifiers)
  }

  const handleDeleteQualifier = async ({ promotionalCategoryId }) => {
    const newQualifiers = [...qualifyingPromotionalCategoryIds]
    newQualifiers.splice(
      newQualifiers.findIndex((id) => id == promotionalCategoryId),
      1,
    )
    setQualifyingPromotionalCategoryIds(newQualifiers)
  }

  const qualifyingItemComponent = ({
    disabled,
    promotionalCategoryId,
    onChange = () => {},
  }: {
    disabled: boolean
    promotionalCategoryId: number | null
    onChange?: (value: number) => void
  }) => (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel>Item Category</InputLabel>
      <PromotionalCategoriesSelect
        disabled={disabled}
        onChange={onChange}
        value={promotionalCategoryId}
      />
    </FormControl>
  )

  return (
    <div className={className || ''}>
      <Paper className={classes.paperWrapper}>
        <Typography sx={{ pb: 2 }} variant='h6'>
          Discounted Item
        </Typography>

        <div>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel>Discounted Item Category</InputLabel>
            <PromotionalCategoriesSelect
              disabled={!editing}
              onChange={handleDiscountItemChange}
              value={discountedItemPromotionalCategoryId}
            />
          </FormControl>
        </div>

        <div>
          <FormControl sx={{ mt: 2 }}>
            <FormLabel>Amount To Discount</FormLabel>
            <RadioGroup
              onChange={handleChangeDiscountMode}
              row
              value={discountMode}
            >
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Percentage'
                value='percentage'
              />
              <FormControlLabel
                control={<Radio />}
                disabled={!editing}
                label='Cents'
                value='cents'
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div>{discountAmountInput[discountMode]()}</div>
      </Paper>

      <Paper
        className={[classes.paperWrapper, classes.qualifyingWrapper].join(' ')}
      >
        <MaterialTable
          columns={[
            {
              title: 'Item In Cart',
              field: 'promotionalCategoryId',
              render: ({ promotionalCategoryId }) =>
                qualifyingItemComponent({
                  disabled: true,
                  promotionalCategoryId,
                }),
              editComponent: ({
                onChange,
                rowData: { promotionalCategoryId },
              }) =>
                qualifyingItemComponent({
                  disabled: false,
                  onChange,
                  promotionalCategoryId,
                }),
            },
          ]}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          data={qualifyingTableData}
          editable={
            editing
              ? {
                  onRowUpdate: handleUpdateQualifier,
                  onRowAdd: handleCreateQualifier,
                  onRowDelete: handleDeleteQualifier,
                }
              : {}
          }
          options={{
            actionsColumnIndex: 99,
            paging: false,
            addRowPosition: 'first',
            tableLayout: 'fixed',
            search: false,
          }}
          title='Qualifying Items'
        />
        <p>
          You do not need to include the discounted item in this list, these are
          the <em>other</em> items that must be present in the cart for the
          discount item to qualify.
        </p>
      </Paper>
    </div>
  )
}

export default Combo

type PropsType = {
  className: string | null
  promotion: PromotionType | null
  editing: boolean
  onConfigurationChange: (object) => void
}

type ConfigurationType = {
  cents_off: number
  percentage_off: number
  discounted_promotional_category_id: number | null
  qualifying_promotional_category_ids: number[]
}

const useStyles = makeStyles()((theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
  },
  qualifyingWrapper: {
    marginTop: theme.spacing(2),
  },
  paperMargin: {
    marginTop: theme.spacing(2),
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}))
