import ErrorBoundary from '@components/shared/error-boundary'
import React, { ReactElement, useState } from 'react'
import Table, { ColumnsType } from '@components/shared/table'
import { Paper } from '@mui/material'

import { AppDispatch } from '@types'
import { EmailOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { formatLong } from '@utils/date-utils'
import { resendRefundReceipts } from '@store/reducers/orders/actions'
import { useDispatch } from 'react-redux'

type User = {
  id: number
  name: string
}

type Product = {
  id: number
  name: string
  order_product_id: number
}

type Refund = {
  created_at: string
  explanation?: string
  products: Product[]
  reason?: string
  source: string
  user?: User
  order_id: number
}

type Props = {
  refunds: Refund[]
  orderId: number
  title?: string
  className?: string
}

const renderProducts = (refund: Refund) => (
  <ul>
    {refund.products.length == 0
      ? 'Some historic orders may not have tracked product refunds.'
      : null}
    {refund.products.map((product) => {
      return (
        <li key={product.order_product_id}>
          <Link to={`/products/${product.id}`}>{product.name}</Link>
        </li>
      )
    })}
  </ul>
)

const renderUser = (refund: Refund) =>
  refund.user ? (
    <Link to={'/users/' + refund.user.id}>{refund.user.name}</Link>
  ) : refund.source == 'barista' ? (
    'Unassigned Barista'
  ) : (
    'Unknown'
  )

const columns: ColumnsType<Refund> = [
  {
    field: 'created_at',
    title: 'Date/Time',
    render: (refund) => formatLong(refund.created_at),
  },
  {
    field: 'products',
    title: 'Products',
    render: renderProducts,
  },
  {
    field: 'user',
    title: 'User',
    render: renderUser,
  },
  {
    field: 'source',
    title: 'Source',
    render: (refund) => refund.source || 'Unknown',
  },
  {
    field: 'reason',
    title: 'Reason',
    render: (refund) => refund.reason || 'Not Set',
  },
  {
    field: 'explanation',
    title: 'Explanation',
    render: (refund) => refund.explanation || 'No extended explanation given',
  },
]

const Refunds = ({
  refunds,
  title,
  className,
  orderId,
}: Props): ReactElement => {
  const [receiptSubmitting, setReceiptSubmitting] = useState(false)
  const dispatch = useDispatch<AppDispatch>()

  const handleResendRefundReceipts = async () => {
    setReceiptSubmitting(true)
    await dispatch(resendRefundReceipts(orderId))
    setReceiptSubmitting(false)
  }

  return (
    <ErrorBoundary>
      <Paper className={className}>
        <Table
          columns={columns}
          data={refunds}
          rowKey='id'
          title={title}
          toolbarButtons={[
            {
              disabled: receiptSubmitting,
              disableTooltip: true,
              title: 'Resend Receipt',
              variant: 'outlined',
              onClick: handleResendRefundReceipts,
              startIcon: <EmailOutlined />,
            },
          ]}
        />
      </Paper>
    </ErrorBoundary>
  )
}

Refunds.defaultProps = {
  title: 'Refunds',
}

export default Refunds
