import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Form from '../form'
import FormImageInput from '../form-image-input'
import FormInput from '../form-input'
import FormSwitch from '../form-switch'
import TimezoneSelector, { defaultTimezone } from '../timezone-selector'
import VendorRegionSelect from '@shared/vendor-region-select'
import dayjs from 'dayjs'
import { TimePicker } from '@mui/x-date-pickers'
import { useAllActions } from '@hooks'

const useStyles = makeStyles()((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
    color: 'white',
  },
  vendorRegionSelect: {
    width: '100%',
    marginBottom: '16px',
  },
  timeZone: {
    marginBottom: '16px',
  },
}))

function StoreForm(props) {
  const { classes } = useStyles()
  const store = props.store || {}
  const [imageUrl, setImageUrl] = useState(store.image_url)
  const [imageName, setImageName] = useState('')
  const [headerImageOneUrl, setHeaderImageOneUrl] = useState(
    store.header_image_one_url,
  )
  const [headerImageOneName, setHeaderImageOneName] = useState('')
  const [headerImageTwoUrl, setHeaderImageTwoUrl] = useState(
    store.header_image_two_url,
  )
  const [headerImageTwoName, setHeaderImageTwoName] = useState('')
  const [name, setName] = useState(store.name || '')
  const [netsuiteId, setNetsuiteId] = useState(store.netsuite_id || '')
  const [state, setState] = useState(store.state || '')
  const [city, setCity] = useState(store.city || '')
  const [postalCode, setPostalCode] = useState(store.postal_code || '')
  const [address, setAddress] = useState(store.address || '')
  const [region, setRegion] = useState(store.region || '')
  const [area, setArea] = useState(store.area || '')
  const [hasDriveThru, setHasDriveThru] = useState(
    store.has_drive_thru || false,
  )
  const [latitude, setLatitude] = useState(store.latitude || '')
  const [longitude, setLongitude] = useState(store.longitude || '')
  const [timeZone, setTimeZone] = useState(store.time_zone || defaultTimezone)
  const [phone, setPhone] = useState(store.phone || '')
  const [email, setEmail] = useState(store.email_address)
  const [maxPrepTime, setMaxPrepTime] = useState(store.max_prep_time || 150)
  const [bdaPassword, setBdaPassword] = useState(store.bda_password || '')
  const [minutesToClosingWarning, setMinutesToClosingWarning] = useState(
    store.minutes_to_closing_warning || 10,
  )
  const [acceptsMobileOrders, setAcceptsMobileOrders] = useState(
    store.accepts_mobile_orders == null ? true : store.accepts_mobile_orders,
  )
  const [bdDestinationID, setBdDestinationID] = useState(
    store.bd_destination_id || '',
  )
  const [yextId, setYextId] = useState(store.yext_id || '')
  const [doordashId, setDoordashId] = useState(store.doordash_id || '')
  const [description, setDescription] = useState(store.description || '')
  const [curbsideEnabled, setCurbsideEnabled] = useState(
    store.curbside_enabled || false,
  )
  const [curbsideOnline, setCurbsideOnline] = useState(
    store.curbside_online || false,
  )
  const [vendorRegionId, setVendorRegionId] = useState(
    store.vendor_region_id || null,
  )
  const [afternoonAt, setAfternoonAt] = useState(store.afternoon_at || '')
  const [eveningAt, setEveningAt] = useState(store.evening_at || '')
  const { setFlashMessage } = useAllActions()

  const getBase64ImageFromUrl = async (imageUrl) => {
    // Get the base 64 image data
    return new Promise((resolve) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            const base64Data = reader.result
            resolve(base64Data)
          }
        })
    })
  }

  const getImage = async () => {
    // No image URL => no Image
    if (imageUrl == null || imageUrl === store.image_url) return null
    return getBase64ImageFromUrl(imageUrl)
  }

  const getHeaderImageOne = async () => {
    if (
      headerImageOneUrl == null ||
      headerImageOneUrl === store.header_image_one_url
    )
      return null
    return getBase64ImageFromUrl(headerImageOneUrl)
  }

  const getHeaderImageTwo = async () => {
    if (
      headerImageTwoUrl == null ||
      headerImageTwoUrl === store.header_image_two_url
    )
      return null
    return getBase64ImageFromUrl(headerImageTwoUrl)
  }

  const evalBdDestination = (id) => {
    if (id === '') {
      evalCurbsideEnabled(false)
    }
    setBdDestinationID(id)
  }

  const evalCurbsideEnabled = (enabled) => {
    if (enabled === false) {
      setCurbsideOnline(false)
    }
    setCurbsideEnabled(enabled)
  }

  const getContentfulId = () => {
    return Math.random().toString(36).substr(2, 9)
  }

  const getStore = async () => {
    if (!name) throw new Error('Must enter a name.')
    if (!netsuiteId) throw new Error('Must enter a Netsuite ID.')

    const data = {
      name,
      netsuite_id: netsuiteId,
      contentful_id: getContentfulId(),
      phone,
      email_address: email,
      state,
      city,
      postal_code: postalCode,
      address,
      region,
      area,
      has_drive_thru: hasDriveThru,
      longitude,
      latitude,
      time_zone: timeZone,
      bda_password: bdaPassword,
      max_prep_time: maxPrepTime,
      minutes_to_closing_warning: minutesToClosingWarning,
      accepts_mobile_orders: acceptsMobileOrders,
      curbside_enabled: curbsideEnabled,
      curbside_online: curbsideOnline,
      bd_destination_id: bdDestinationID,
      yext_id: yextId,
      doordash_id: doordashId,
      vendor_region_id: vendorRegionId,
      description: description,
      afternoon_at: afternoonAt,
      evening_at: eveningAt,
    }

    const image = await getImage()
    if (image != null && imageName != null) {
      data.store_image_data = image
      data.image_filename = imageName
    }
    const headerImageOne = await getHeaderImageOne()
    if (headerImageOne != null && headerImageOneName != null) {
      data.store_header_image_one_data = headerImageOne
      data.store_header_image_one_filename = headerImageOneName
    }
    const headerImageTwo = await getHeaderImageTwo()
    if (headerImageTwo != null && headerImageTwoName != null) {
      data.store_header_image_two_data = headerImageTwo
      data.store_header_image_two_filename = headerImageTwoName
    }

    return data
  }

  const handleDayPartChange = (onChange) => (newDate) => {
    onChange(newDate.format('HH:mm'))
  }

  return (
    <Form className={props.className} title={props.title}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormImageInput
            imageUrl={imageUrl}
            onChange={(data) => {
              setImageName(data.name)
              setImageUrl(data.url)
            }}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setName} title='Name' value={name} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ maxlength: '255' }}
            onChangeText={setDescription}
            title='Description'
            value={description}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            helperText='This is not the Netsuite store number but the internal Netsuite ID'
            onChangeText={setNetsuiteId}
            title='Netsuite ID'
            value={netsuiteId}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setState} title='State' value={state} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setCity} title='City' value={city} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            onChangeText={setPostalCode}
            title='Postal Code'
            value={postalCode}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            onChangeText={setAddress}
            title='Address'
            value={address}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            helperText='Region the customer selects to find the store'
            onChangeText={setRegion}
            title='Region'
            value={region}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <VendorRegionSelect
            className={classes.vendorRegionSelect}
            onChange={setVendorRegionId}
            value={vendorRegionId}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setArea} title='Area' value={area} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'number' }}
            onChangeText={(t) => setLatitude(parseFloat(t))}
            title='Latitude'
            value={latitude}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'number' }}
            onChangeText={(t) => setLongitude(parseFloat(t))}
            title='Longitude'
            value={longitude}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <TimezoneSelector
            className={classes.timeZone}
            onChange={setTimeZone}
            value={timeZone}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setPhone} title='Phone' value={phone} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput onChangeText={setEmail} title='Email' value={email} />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            onChangeText={setBdaPassword}
            title='BDA Password'
            value={bdaPassword}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'number' }}
            onChangeText={(t) => setMaxPrepTime(parseInt(t, 10))}
            title='Max Prep Time'
            value={maxPrepTime.toString()}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'number' }}
            onChangeText={(t) => setMinutesToClosingWarning(parseInt(t, 10))}
            title='Minutes to Closing Warning'
            value={minutesToClosingWarning.toString()}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormSwitch
            checked={hasDriveThru}
            helperText='Set whether or not this store has a drive thru.'
            onChange={setHasDriveThru}
            title='Has a Drive Thru'
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormSwitch
            checked={acceptsMobileOrders}
            helperText='Set whether or not this store accepts mobile orders.'
            onChange={setAcceptsMobileOrders}
            title='Accepts Mobile Orders'
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormSwitch
            checked={curbsideEnabled}
            disabled={bdDestinationID.length === 0}
            helperText='Set whether or not this store accepts curbside orders.'
            onChange={evalCurbsideEnabled}
            title='Accepts Curbside Orders'
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'string' }}
            onChangeText={(id) => evalBdDestination(id)}
            title='Blue Dot Destination ID'
            value={bdDestinationID}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'string' }}
            onChangeText={setYextId}
            title='Yext Entity ID'
            value={yextId}
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <FormInput
            inputProps={{ type: 'string' }}
            onChangeText={setDoordashId}
            title='Doordash Store ID'
            value={doordashId}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormControl>
                <InputLabel>Morning Begins At</InputLabel>
                <Select disabled='disabled' value='1'>
                  <MenuItem key={0} value='1'>
                    Store Opening
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TimePicker
                label='Afternoon Begins At'
                onChange={handleDayPartChange(setAfternoonAt)}
                value={
                  afternoonAt == '' ? null : dayjs('0000-01-01 ' + afternoonAt)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TimePicker
                label='Evening Begins At'
                onChange={handleDayPartChange(setEveningAt)}
                value={
                  eveningAt == '' ? null : dayjs('0000-01-01 ' + eveningAt)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <span>Header Image One</span>
          <FormImageInput
            imageUrl={headerImageOneUrl}
            onChange={(data) => {
              setHeaderImageOneName(data.name)
              setHeaderImageOneUrl(data.url)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <span>Header Image Two</span>
          <FormImageInput
            imageUrl={headerImageTwoUrl}
            onChange={(data) => {
              setHeaderImageTwoName(data.name)
              setHeaderImageTwoUrl(data.url)
            }}
          />
        </Grid>
      </Grid>
      <Button
        className={classes.submitButton}
        color='secondary'
        onClick={async () => {
          try {
            const store = await getStore()
            if (store != null) props.onSubmit(store)
          } catch (error) {
            setFlashMessage(error.message, 'error')
          }
        }}
        variant='contained'
      >
        Submit
      </Button>
    </Form>
  )
}

StoreForm.propTypes = {
  onSubmit: PropTypes.func,
  store: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.node,
}

export default StoreForm
