import createAction from '@store/actions/create-action'
import { ApiDispatch } from '@types'
import {
  REFUNDED_PRODUCTS_FAILURE,
  REFUNDED_PRODUCTS_SUCCESS,
  RESEND_RECEIPT_FAILURE,
  RESEND_RECEIPT_SUCCESS,
} from '@store/action-list'
import { apiAction } from '@store/actions/api'

export const refundProducts = (
  orderId: number,
  refundedItems: number[],
  refundReasonId: number,
): ApiDispatch => {
  return apiAction({
    method: 'POST',
    url: `/p4/orders/${orderId}/refund`,
    data: {
      order_product_ids: refundedItems,
      reason_id: refundReasonId,
    },
    actionCallbacks: {
      success: createAction(REFUNDED_PRODUCTS_SUCCESS),
      failure: createAction(REFUNDED_PRODUCTS_FAILURE),
    },
  })
}

export const resendReceipt = (orderId: number): ApiDispatch => {
  return apiAction({
    method: 'POST',
    url: `/p4/orders/${orderId}/resendReceipt`,
    actionCallbacks: {
      success: createAction(RESEND_RECEIPT_SUCCESS),
      failure: createAction(RESEND_RECEIPT_FAILURE),
    },
  })
}

export const resendRefundReceipts = (orderId: number): ApiDispatch => {
  return apiAction({
    method: 'POST',
    url: `/p4/orders/${orderId}/resendRefundReceipts`,
    actionCallbacks: {
      success: createAction(RESEND_RECEIPT_SUCCESS),
      failure: createAction(RESEND_RECEIPT_FAILURE),
    },
  })
}
