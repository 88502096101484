import {
  SET_TODAYS_INVENTORY,
  UPDATE_TODAYS_INVENTORY_FOR_VARIANT,
} from '../action-list'

/**
 * State has format: {storeId: {variantId: {quantity: number, available: boolean, ...}}
 */
const initialState = {}

export default (state = initialState, action) => {
  // The inventory passed here should be a list of inventory records
  const { inventory, storeId, variantId } = action

  switch (action.type) {
    case SET_TODAYS_INVENTORY:
      console.log(`Setting today's inventory with ${inventory.length} records`)
      return {
        ...state,
        [storeId]: inventory.reduce((acc, next) => {
          acc[next.variant_id] = next
          return acc
        }, {}),
      }
    case UPDATE_TODAYS_INVENTORY_FOR_VARIANT:
      console.log(`Updating inventory for variant ${variantId}`)
      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          [variantId]: inventory,
        },
      }
    default:
      return state
  }
}
