import ProductsStoresTable from './products-stores-table'
import React, { ReactElement, useState } from 'react'
import Toolbar from '@components/shared/toolbar'
import { CircularProgress, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useAllActions } from '@hooks/use-all-actions'

interface Props {
  productsStores: any
  setStoreVariantPrice(storeId, variantId, price): Promise<any>
  createProductStore(data): Promise<any>
  removeProductStore(any): Promise<any>
  fetch(): Promise<any>
  product: any
  className: any
}

const useStyles = makeStyles()((theme) => ({
  topContent: {
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
  },
  basicInfo: {
    flex: 3,
    marginRight: theme.spacing(2),
  },
  content: {
    width: '300px',
    marginBottom: theme.spacing(3),
  },
}))

const ProductsStores = ({
  className,
  productsStores,
  createProductStore,
  setStoreVariantPrice,
  removeProductStore,
  fetch,
  product,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const { addToAllStores, setFlashMessage } = useAllActions()

  const addToAll = () => {
    setIsLoading(true)
    addToAllStores(product.id)
      .then((errors) => {
        if (errors.length > 1) {
          setFlashMessage(errors.join(', '), 'error')
        } else {
          fetch()
          setFlashMessage('Product Added to all Stores.', 'success')
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setFlashMessage(err.message, 'error')
        setIsLoading(false)
      })
  }

  const content = isLoading ? (
    <div>
      <div className={classes.topContent}>
        <Paper className={classes.basicInfo}>
          <Toolbar title='Stores' />
          <div className={classes.container}>
            {isLoading ? <CircularProgress /> : <div />}
          </div>
        </Paper>
      </div>
    </div>
  ) : (
    <ProductsStoresTable
      addToAllStores={addToAll}
      createProductStore={createProductStore}
      product={product}
      productsStores={productsStores}
      removeProductStore={removeProductStore}
      setStoreVariantPrice={setStoreVariantPrice}
    />
  )

  return <div className={className}>{content}</div>
}

export default ProductsStores
