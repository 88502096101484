import React from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'

import {
  VpnKey as CurrentUserIcon,
  AccountTree as CustomContentIcon,
  Face as EmployeesIcon,
  LocalAtm as LocalAtmIcon,
  Loyalty as LoyaltyIcon,
  Menu as MenuIcon,
  MenuBook as ProductsIcon,
  ArtTrack as RecProductsIcon,
  Extension as RecommendationEngineIcon,
  Receipt as ReportsIcon,
  Gavel as RoleIcon,
  Search as SearchIcon,
  Store as StoresIcon,
  LocalOffer as TagsIcon,
  DynamicFeed as VerticalScrollIcon,
} from '@mui/icons-material'
import { Link, useLocation } from 'react-router-dom'
import { renderIfPermitted } from '@utils/has-permission'

/**
 * Edit this array to make changes to the navigation drawer.
 */
const links = [
  {
    title: 'Search',
    icon: <SearchIcon />,
    route: '/search',
  },
  {
    title: 'Roles',
    icon: <RoleIcon />,
    route: '/roles',
    permission: 'roles:update',
  },
  {
    title: 'Employees',
    icon: <EmployeesIcon />,
    route: '/employees',
    permission: 'users:update',
  },
  {
    title: 'Stores',
    icon: <StoresIcon />,
    route: '/stores',
  },
  {
    title: 'Products',
    icon: <ProductsIcon />,
    route: '/products',
    nested: [
      {
        title: 'Attributes',
        icon: <TagsIcon />,
        route: '/attributes',
      },
    ],
  },
  {
    title: 'Tags',
    icon: <TagsIcon />,
    route: '/tags',
  },
  {
    title: 'Vertical Scroll Cards',
    icon: <VerticalScrollIcon />,
    route: '/vertical-cards',
    permission: 'vertical-scrolls:update',
  },
  {
    title: 'Custom Content',
    icon: <CustomContentIcon />,
    route: '/custom-content',
    permission: 'custom-content:update',
  },
  {
    title: 'Reports',
    icon: <ReportsIcon />,
    route: '/reports',
    permission: 'reports:read',
  },
  {
    title: 'Recommended Products',
    icon: <RecProductsIcon />,
    route: '/recommended-products',
    permission: 'recommended-products:update',
  },
  {
    title: 'Recommendation Engine',
    icon: <RecommendationEngineIcon />,
    route: '/recommendation-engine',
    permission: 'recommended-products:update',
  },
  {
    title: 'Promotions',
    icon: <LoyaltyIcon />,
    route: '/promotions',
  },
  {
    title: 'Surcharges & Tax',
    icon: <ReportsIcon />,
    route: '/surcharges',
    permission: 'tax-rates:update',
  },
  {
    title: 'Menus',
    icon: <ReportsIcon />,
    route: '/menus',
    permission: 'categories:update',
  },
  {
    title: 'Promotional Categories',
    icon: <TagsIcon />,
    route: '/promotional-categories',
    permission: 'promotions:update',
  },
  {
    title: 'PLU Update',
    icon: <LocalAtmIcon />,
    route: '/plu-update',
    permission: 'mass-price-change:update',
  },
]

const drawerWidth = 240

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Unexpected value type of MemberExpression.
const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      zIndex: '1201',
    },
  },
  appVersions: {
    marginTop: 'auto',
    marginBottom: '20px',
    padding: '8px 16px',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  textField: {
    marginLeft: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  underline: {
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  title: {
    color: 'white',
  },
}))

function NavBar(props) {
  const { classes } = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  const firstRoute = '/' + (pathnames.length > 0 ? pathnames[0] : '')

  const drawer = (
    <>
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {links.map((config) => {
            const item = (
              <div key={config.title}>
                <ListItemButton
                  component={Link}
                  selected={config.route === firstRoute}
                  to={config.route}
                >
                  <ListItemIcon>{config.icon}</ListItemIcon>
                  <ListItemText primary={config.title} />
                </ListItemButton>
                {config.nested && (
                  <List component='div' disablePadding>
                    {config.nested?.map((nestedItem) => {
                      const subItem = (
                        <ListItemButton
                          component={Link}
                          key={nestedItem.title}
                          selected={nestedItem.route === firstRoute}
                          sx={{ pl: 4 }}
                          to={nestedItem.route}
                        >
                          <ListItemIcon>{nestedItem.icon}</ListItemIcon>
                          <ListItemText primary={nestedItem.title} />
                        </ListItemButton>
                      )

                      return renderIfPermitted(subItem, nestedItem.permission)
                    })}
                  </List>
                )}
              </div>
            )

            return renderIfPermitted(item, config.permission)
          })}
        </List>
        <List>
          <ListItem
            button
            component={Link}
            selected={firstRoute === '/current-user'}
            to={'/current-user'}
          >
            <ListItemIcon>
              <CurrentUserIcon />
            </ListItemIcon>
            <ListItemText primary={'Current User'} />
          </ListItem>
        </List>
      </div>
      <div className={classes.appVersions}>
        <div>frontend: {VERSION}</div>
      </div>
    </>
  )

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position='fixed'>
        <Toolbar className={classes.topBar}>
          <IconButton
            aria-label='open drawer'
            className={classes.menuButton}
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} noWrap variant='h6'>
            Philz Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <nav aria-label='mailbox folders' className={classes.drawer}>
          <Hidden implementation='css' smUp>
            <Drawer
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              onClose={handleDrawerToggle}
              open={mobileOpen}
              variant='temporary'
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden implementation='css' smDown>
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              open
              variant='permanent'
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* eslint-disable-next-line */}
          {props.children}
        </main>
      </div>
    </React.Fragment>
  )
}

NavBar.propTypes = {
  children: PropTypes.node,
}

export default NavBar
