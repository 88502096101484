import AttributeSelector from '@components/shared/attribute-selector'
import React, { ChangeEvent, ReactElement } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ProductTypeType } from '@types'

const AttributeForm = ({
  name,
  label,
  productType,
  isAdjective,
  includesGoods,
  modifiedBy,
  onNameChange,
  onLabelChange,
  onProductTypeChange,
  onIsAdjectiveChange,
  onIncludesGoodsChange: handleIncludesGoodsChange,
  onModifiedByChange: handleModifiedByChange,
}: Props): ReactElement => {
  const handleProductTypeChange = ({
    target: { value },
  }: SelectChangeEvent) => {
    onProductTypeChange(value as ProductTypeType)
  }

  const handleNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onNameChange(value as string)
  }

  const handleLabelChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onLabelChange(value as string)
  }

  const handleBooleanChange = (onChangeHandler) => (_, value: boolean) => {
    onChangeHandler(value)
  }

  return (
    <Grid container spacing={5}>
      <Grid item lg={4} xs={12}>
        <TextField
          fullWidth
          label='Name'
          onChange={handleNameChange}
          value={name}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <TextField
          fullWidth
          label='Label'
          onChange={handleLabelChange}
          value={label}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <FormControl fullWidth>
          <InputLabel>Product Type</InputLabel>
          <Select
            fullWidth
            onChange={handleProductTypeChange}
            value={productType}
          >
            <MenuItem value='beverage'>Beverage</MenuItem>
            <MenuItem value='bag'>Bag</MenuItem>
            <MenuItem value='food'>Food</MenuItem>
            <MenuItem value='bottle'>Bottle</MenuItem>
            <MenuItem value='merchandise'>Merchandise</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={4} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAdjective}
              onChange={handleBooleanChange(onIsAdjectiveChange)}
            />
          }
          label='Is Adjective'
        />
        <p>
          This controls some of the wording to the customer when creating
          sentences, think about if the values of the attribute are adjectives.
        </p>
      </Grid>
      <Grid item lg={4} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={includesGoods}
              onChange={handleBooleanChange(handleIncludesGoodsChange)}
            />
          }
          label='Includes Goods'
        />
        <p>
          This definition means that the selected values are physical goods.
          When true a Barista will be able to disable the attribute values for
          the day (i.e. Oat Milk is a good and you can mark that you are out of
          it).
        </p>
      </Grid>
      <Grid item lg={4} xs={12}>
        <AttributeSelector
          inputLabel='Modified By'
          nullValue='Not Modified'
          onChange={handleModifiedByChange}
          productType={productType}
          value={modifiedBy}
        />
        <p>
          Select an attribute only if there is another one that describes a
          modification to this one. Such as sweetener type would say it is
          modified by sweetener amount.
        </p>
      </Grid>
    </Grid>
  )
}

export default AttributeForm

type Props = {
  name: string
  label: string
  productType: ProductTypeType
  isAdjective: boolean
  includesGoods: boolean
  modifiedBy: number | null
  onNameChange: (newValue: string) => void
  onLabelChange: (newValue: string) => void
  onProductTypeChange: (newValue: ProductTypeType) => void
  onIsAdjectiveChange: (newValue: boolean) => void
  onIncludesGoodsChange: (newValue: boolean) => void
  onModifiedByChange: (newValue: number | null) => void
}
