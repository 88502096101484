import flashMessage from './flash-message'
import { all } from 'redux-saga/effects'
import {
  watchChangeVariants,
  watchDeleteProductAttributes,
} from './attributes-variants'

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga(): any {
  yield all([
    flashMessage(),
    watchDeleteProductAttributes(),
    watchChangeVariants(),
  ])
}
