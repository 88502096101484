import React, { ReactElement, memo, useEffect } from 'react'
import useSelector from '@hooks/use-selector'
import { AppDispatch, ProductTypeType } from '@types'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { getAttributes } from '@store/actions/attributes'
import { selectAttributesByProductType } from '@store/selectors/attributes'
import { useDispatch } from 'react-redux'

const AttributeSelector = ({
  inputLabel = 'Attribute',
  value,
  onChange: handleOnChange,
  productType,
  nullValue,
}: Props): ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const attributes = useSelector(selectAttributesByProductType, productType)

  useEffect(() => {
    if (attributes && attributes.length) return

    dispatch(getAttributes())
  }, [])

  const handleAttributeChange = ({
    target: { value },
  }: SelectChangeEvent<number>) => {
    if (nullValue && value == 0) {
      handleOnChange(null)
      return
    }
    handleOnChange(value as number)
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{inputLabel}</InputLabel>
      <Select onChange={handleAttributeChange} value={value ?? 0}>
        {nullValue && <MenuItem value={0}>{nullValue}</MenuItem>}
        {attributes.map((attribute) => (
          <MenuItem key={attribute.id} value={attribute.id}>
            {attribute.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default memo(AttributeSelector)

type DefaultProps = {
  productType: ProductTypeType
  inputLabel?: string
}

type NonNullableProps = DefaultProps & {
  nullValue?: never
  value: number
  onChange: (value: number) => void
}

type NullableProps = DefaultProps & {
  nullValue: string
  value: number | null
  onChange: (value: null | number) => void
}

type Props = NonNullableProps | NullableProps
