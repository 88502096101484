import * as api from '@utils/api'
import PromotionalCategoriesSelect from '@components/shared/promotional-categories-select'
import React, { ChangeEvent, ReactElement, useState } from 'react'
import useAllActions from '@hooks/use-all-actions'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Paper,
  TextField,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type Props = {
  storeId: number
  minOrders: number
  maxOrders: number
}

const useStyles = makeStyles()(() => ({
  container: {
    padding: '1px 20px 20px',
    margin: '20px 0',
  },
  numberField: {
    minWidth: '150px',
  },
}))

const SimulateOrders = ({
  storeId,
  minOrders = 1,
  maxOrders = 25,
}: Props): ReactElement => {
  const { classes } = useStyles()
  const [includeFood, setIncludeFood] = useState(false)
  const [numberOfOrders, setNumberOfOrders] = useState(1)
  const [drinksPromotionalCategoryId, setDrinksPromotionalCategoryId] =
    useState<number | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { setFlashMessage } = useAllActions()

  const handleIncludeFoodChange = (_: any, checked: boolean) => {
    setIncludeFood(checked)
  }
  const handleNumberOfOrdersChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(value, 10)
    if (newValue < minOrders) newValue = minOrders
    if (newValue > maxOrders) newValue = maxOrders
    setNumberOfOrders(newValue)
  }

  const handleOnClickGenerateOrders = async () => {
    setIsSubmitting(true)
    try {
      await api.POST(`/p4/stores/${storeId}/simulate/orders`, {
        include_food: includeFood,
        orders_count: numberOfOrders,
        drinks_promotional_category_id: drinksPromotionalCategoryId,
      })
      setFlashMessage('Orders Generated', 'success')
    } catch (error: any) {
      setFlashMessage(error.message, 'error')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Paper className={classes.container} elevation={2}>
      <h2>Simulate Orders</h2>
      <p>
        {`Please log in all Baristas before simulating orders. The orders
          will be spread over time based on the availability of this store.
          You may need to refresh the page to see the orders in the list above.`}
      </p>
      <FormControl component='fieldset'>
        <FormGroup row sx={{ mb: 5, mt: 2 }}>
          <TextField
            className={classes.numberField}
            inputProps={{ min: minOrders, max: maxOrders }}
            label='Number of Orders'
            onChange={handleNumberOfOrdersChange}
            type='number'
            value={numberOfOrders}
          />
          <FormControl sx={{ minWidth: 200, ml: 2 }}>
            <InputLabel>Drink Category</InputLabel>
            <PromotionalCategoriesSelect
              nullLabel='Any / All'
              onChange={setDrinksPromotionalCategoryId}
              value={drinksPromotionalCategoryId}
            />
          </FormControl>
          <FormControlLabel
            control={<Checkbox />}
            label='Include Food'
            labelPlacement='start'
            onChange={handleIncludeFoodChange}
            value={includeFood}
          />
        </FormGroup>
        <FormGroup row>
          <Button
            disabled={isSubmitting}
            onClick={handleOnClickGenerateOrders}
            variant='contained'
          >
            Generate Orders
          </Button>
        </FormGroup>
      </FormControl>
    </Paper>
  )
}

export default SimulateOrders
