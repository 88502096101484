import {
  createVariantAction,
  deleteVariantAction,
  getProductVariants,
  updateVariantAction,
} from '@store/actions/variants'
import {
  deleteProductAttributeAction,
  deleteProductAttributeValueAction,
} from '@store/actions/product-attributes'
import { fetchProductsStoresForProductId } from '@services/product-service'
import { put, takeEvery } from 'redux-saga/effects'

const attributeDeletes = [
  deleteProductAttributeAction,
  deleteProductAttributeValueAction,
]

const variantChanges = [
  createVariantAction,
  deleteVariantAction,
  updateVariantAction,
]

export function* watchDeleteProductAttributes() {
  yield takeEvery(attributeDeletes, updateVariants)
}

function* updateVariants({ payload }) {
  const productId = payload.product_id ?? payload.product_attribute?.product_id
  if (productId) {
    yield put(getProductVariants({ id: productId } as any))
  }
}

export function* watchChangeVariants() {
  yield takeEvery(variantChanges, updateMenu)
}

function* updateMenu({ payload }) {
  const productId = payload.product_id ?? payload.product?.id
  if (productId) {
    yield put(fetchProductsStoresForProductId(productId))
  }
}
